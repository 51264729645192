.searvicDEtailDiv {
  .rightService {
    width: 100%;
    max-width: 90%;
  }
}

.serviceDataDet {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
  overflow: hidden;

  h1 {
    font-size: 35px;
    text-transform: uppercase;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
  }
  p {
    padding-top: 1rem;
    font-size: 13px;
    color: var(--black);

    line-height: 1.8;
    font-weight: 500;
    letter-spacing: 0;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
  }

  .disSerData {
    display: flex;
    justify-content: center;
  }
}

.detailServHeroSub {
  width: 100%;
  max-width: 70%;
  position: relative;
  background-color: var(--primary-color);
  h2 {
    padding: 3px;
    line-height: 1.5;
  }
}
@media (max-width: 768px) {
  .detailServHeroSub {
    max-width: 100%;
  }

  .heroServive {
    & .rightService {
      h1 {
        width: fit-content !important;
        white-space: wrap;
        overflow: hidden;
        font-size: 31px;
      }
    }
  }
  .serviceDataDet {
    h1 {
      font-size: 31px;
    }
  }
}
