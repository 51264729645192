.loaderDiv {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #000000ce;
  backdrop-filter: blur(5px);
  z-index: 999;
}
.loader:after,
.loader:before {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  background: #111517;
  -webkit-transition: 0.5s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  -o-transition: 0.5s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  transition: 0.5s cubic-bezier(0.858, 0.01, 0.068, 0.99);
}

.loader:before {
  left: 100%;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  visibility: visible;
}

.loader:after {
  left: -100%;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  visibility: hidden;
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.loading {
  position: fixed;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  visibility: visible;
  -webkit-transition: 0.5s cubic-bezier(0.76, 0.06, 0.85, 0.07);
  -o-transition: 0.5s cubic-bezier(0.76, 0.06, 0.85, 0.07);
  transition: 0.5s cubic-bezier(0.76, 0.06, 0.85, 0.07);
  z-index: 101;
  width: 100px;
  height: 100px;
}

.loading:before {
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid #111517;
  border-left-color: #fff;
  -webkit-animation: 1.2s cubic-bezier(0.767, 0.01, 0.18, 1.01) infinite both
    loading-anim;
  animation: 1.2s cubic-bezier(0.767, 0.01, 0.18, 1.01) infinite both
    loading-anim;
  border-radius: 50%;
  display: block;
}

.logo-loading {
  width: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@-webkit-keyframes loading-anim {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading-anim {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader.in:before {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  visibility: hidden;
}

.loader.out:after {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  visibility: visible;
}
