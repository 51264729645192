.ourServiVemainDiv {
  background-color: var(--white);
  padding-top: 3rem;
  padding-bottom: 2rem;
  .OurserHead {
    display: flex;
    justify-content: center;
    h1 {
      font-weight: 600;
      font-size: 45px;
      text-transform: uppercase;
    }

    p {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 400;
    }
  }
}

.ourSerViceContentDiv {
  padding: 0 2rem;
  padding-top: 2rem;
  .ourSeriveData {
    height: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: var(--light-bg);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .serViceImgConty {
      height: 100%;
      width: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: transform 0.5s ease-in-out;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .downDataSerice {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    h1 {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1px;
      font-size: 25px;
    }
    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      letter-spacing: 1px;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .OurSerReadButton {
    display: flex;

    justify-content: center;
    padding-top: 1rem;
    .explorBttn {
      margin-top: 0;
    }
  }
}
@media (max-width: 750px) {
  .ourSerViceContentDiv {
    padding: 0 1.1rem;
  }
}
