.CarrerDiv {
  font-family: "Poppins", sans-serif;
  background-color: var(--black);
  padding-bottom: 2rem;

  .carrerHeading {
    display: flex;
    justify-content: center;

    h1 {
      padding: 2rem 0;
      text-transform: uppercase;
      color: var(--primary-color);
      font-size: 45px;
      font-weight: 600;
      margin-bottom: 1rem;
    }
  }

  .carrerData {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 0 10px 0px #3b3b3b;
    border-radius: 20px;
    padding: 1.5rem;
    transition: max-height 0.5s ease-out, opacity 0.3s ease-out;
    .GraphicDesdiv {
      h2 {
        font-size: 25px;
        color: var(--white);
        margin: 0;
      }
    }

    li {
      color: var(--white);
    }

    .leftySideCareerDe {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      p {
        font-size: 15px;
        margin: 0;
        color: var(--white);
      }
    }

    .RightDivCerrer {
      display: flex;
      flex-wrap: wrap;
      p {
        font-size: 13px;
        color: var(--white);
        margin: 0;
      }
    }
  }

  .applYjobbttn {
    display: inline-flex;
    height: 40px;
    width: 150px;
    border: 2px solid #bfc0c0;
    margin: 20px 20px 20px 20px;
    border-radius: 15px;
    color: #bfc0c0;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 0.8em;
    letter-spacing: 1.5px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  button {
    background-color: transparent;
    border: none;
    color: #bfc0c0;
    text-decoration: none;
    letter-spacing: 1px;
  }
  /*-----------APPlY button-------------*/
  .applYjobbttn {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      button {
        left: 150px;
      }

      .dubArrow {
        left: 0;
      }
    }

    button {
      position: relative;
      left: 0;
      transition: all 0.35s ease-Out;
    }
    img {
      width: 20px;
      height: auto;
    }
    .dubArrow {
      width: 100%;
      height: 100%;
      background: #bfc0c0;
      left: -200px;
      position: absolute;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.35s ease-Out;
      bottom: 0;
    }
  }
}

/*-----------Job Describtion------------------*/
.jobDesc {
  padding-top: 1.4rem;
  color: var(--white);

  overflow: hidden;

  transition: max-height 0.5s ease-out, opacity 0.3s ease-out;
  h2 {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 0.7rem;
  }

  p {
    font-size: 16px;
    font-weight: 400;
  }

  .listJob {
    li {
      margin-top: 0.3rem;
      margin-left: 0.5rem;
    }
  }
  .JobTypeDTa {
    padding-top: 1.3rem;
  }
}

.formDataShow {
  margin-top: 4rem;
  background: var(--black);

  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  color: white;

  input:-webkit-autofill,
  textarea:-webkit-autofill {
    background-color: transparent !important;
    box-shadow: 0 0 0 30px transparent inset !important;
  }

  input:-webkit-autofill:focus,
  textarea:-webkit-autofill:focus {
    background-color: transparent !important;
    box-shadow: 0 0 0 30px transparent inset !important;
  }
}

.formDataShow {
  .form-control {
    background-color: transparent;
    border: 0.5px solid #fff;
    color: var(--white);
    border-radius: 5px;
    padding: 0.8rem;
    margin-bottom: 1.5rem;
    transition: border 0.3s ease, box-shadow 0.3s ease;
    &:hover {
      border: 1px solid var(--primary-color);
      box-shadow: none;
    }
    &:focus {
      border: 1px solid var(--primary-color);
      box-shadow: none;
    }
    select {
      background-color: transparent !important;
    }
    option {
      border: 0.5px solid #fff;
      background-color: var(--black) !important;
    }
  }
}
.formDataShow .form-control::placeholder {
  color: var(--white);
}

.formDataShow .file-input {
  color: var(--white);
  background-color: transparent;
  cursor: pointer;
}

.formDataShow .recaptcha-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.experCOndition {
  position: absolute;
  bottom: -5px;
  margin-left: 10px;
  font-size: 13px;
  color: var(--primary-color);
}
.custom-file-upload {
  position: relative;
  width: 100%;
  display: inline-block;
  border: 0.5px solid var(--white);
  border-radius: 5px;
  transition: border 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    border: 0.5px solid var(--primary-color);
  }
  &:focus {
    border: 0.5px solid var(--primary-color);
  }
}

.file-upload-btn {
  color: var(--white);
  border: none;
  padding: 0.8rem;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    color: var(--white);
  }
}

.upload-icon {
  margin-left: 10px;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.fileUpoloadIcnImg {
  position: absolute;
  right: 3%;
  height: 20px;
  width: 20px;
}

.subMitButton {
  padding: 1rem;
  display: flex;
  justify-content: center;

  .applYjobbttn {
    height: 50px;
    width: 200px;
    padding: 1rem !important;
  }
}

@media screen and (max-width: 768px) {
  .marginClass {
    margin-top: 0;
    margin-bottom: 1.3rem;
  }

  input {
    margin-bottom: 1rem !important;
  }
  .leftySideCareerDe {
    margin: 0 !important;
  }
  .GraphicDesdiv {
    h2 {
      margin-bottom: 1rem !important;
    }
  }
}
