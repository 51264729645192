.trustdDigiContnt {
  justify-content: center;
  p {
    line-height: 1.8;
    letter-spacing: 2px;
    padding-top: 1.5rem;
    text-align: justify;
    color: var(--white);
    font-size: 18px;
    font-weight: 300;
    margin: 0;
  }
}

.trusteddiglPartnerDiv {
  align-items: center;
  display: flex;
  min-height: 100vh;
  background-color: var(--black);

  h1 {
    text-align: center;
    color: var(--primary-color);
    font-size: 45px;
    font-weight: 600;
  }
}
/*------------Blog Styling  Home Page-------*/

.blogHomePage {
  background-color: var(--white);

  .headingBLog {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3rem;
    .headingBLogunderData {
      display: flex;
      flex-direction: column;
      h1 {
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        font-size: 45px;
        font-weight: 600;
      }
      p {
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
}
.blogData {
  overflow: hidden;
  height: 100%;
  width: 100%;

  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--light-bg);

  .blogDataimg {
    width: 100%;
    height: auto;
    object-fit: contain;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  h2 {
    letter-spacing: 1px;
    padding-top: 1rem;
    max-width: 95%;
    font-size: 22px;
  }

  .dateAndTxtDiv {
    padding-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    P {
      font-weight: 500;
    }
  }
}

@media (max-width: 768px) {
  .trusteddiglPartnerDiv {
    min-height: 100%;
    padding: 2rem 0;

    h1 {
      font-size: 34px;
    }
  }

  .trustdDigiContnt {
    p {
      font-size: 16px;
    }
  }
  .headingBLogunderData {
    h1 {
      font-size: 34px !important;
    }
    p {
      font-size: 16px !important;
    }
  }
  .blogData {
    .dateAndTxtDiv {
      padding-top: 0.5rem;
    }
    h2 {
      font-size: 21px;
      max-width: 80%;
    }
  }

  .paddingNone {
    margin-top: 2rem;
    padding: 0;
  }

  .trustdDigiContnt {
    p {
      text-align: center;
      letter-spacing: 1px;
    }
  }
}

@media (min-width: 1800px) {
  .trusteddiglPartnerDiv {
    min-height: 60vh;
  }
}
