.ServiceContainer {
  overflow: hidden;
  height: calc(100vh - 82px);
}
.subHeroCOntnt {
  padding-top: 1rem;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  letter-spacing: 1px;
  gap: 1rem;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 18px;
    background-color: var(--primary-color);
    color: var(--white);
    margin: 0;
    padding: 0.3rem 0.5rem;
  }
}
@media (max-width: 1329px) {
  .subHeroCOntnt {
    gap: 0.5rem;
    flex-wrap: wrap;
  }
}
@media (max-width: 992px) {
  .rightService {
    justify-content: center;
    position: absolute;
    top: 0;
    left: 10%;
  }

  .heroServive {
    & .rightService {
      h1 {
        background-color: var(--primary-color);
      }
    }
  }
}
@media (max-width: 750px) {
  .rightService {
    left: 2%;
  }
}
@media (max-width: 600px) {
  .rightService {
    left: 2%;
  }
}
.heroServive {
  position: relative;
  height: calc(100vh - 81px);
  background-color: var(--darkBlue-color);
  .leftService {
    position: relative;
    img {
      height: calc(100vh - 81px);
      width: 100%;
      object-fit: cover;
    }
  }

  .rightService {
    height: 100%;
    gap: 0.6rem;
    display: flex;
    flex-direction: column;

    text-transform: uppercase;

    font-weight: 700;

    color: var(--white);

    h1 {
      font-size: 32px;
      padding: 0.5rem;
      background-color: var(--primary-color);

      overflow: hidden;
    }
  }
}
/**-----------What We Do ----- */

.whatWeDo {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--black);
  padding-top: 2rem;
  padding-bottom: 2rem;
  .whatWEdoHead {
    display: flex;
    justify-content: center;

    h1 {
      overflow: hidden;
      white-space: nowrap;
      color: var(--primary-color);
      font-size: 45px;
      font-weight: 600;
      transition: width 1s ease-out;
    }
  }
  .underDataWhatwedo {
    padding-top: 1rem;

    display: flex;
    flex-direction: column;
    h1 {
      overflow: hidden;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 22px;
      color: var(--white);
      transition: width 0.5s ease-out;
    }
    span {
      overflow: hidden;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 26px;
      margin: 0;
      color: var(--primary-color);
      transition: width 0.5s ease-out;
    }
  }

  .whatWeDoContent {
    display: flex;
    justify-content: center;
    p {
      overflow: hidden;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: var(--light-borwn);
      margin: 0;
      transition: width 0.5s ease-out;
    }
  }

  .marginClass {
    padding-top: 4rem;
  }
}

@media (max-width: 768px) {
  .whatWeDo {
    padding-top: 1.4rem;

    h1 {
      font-size: 34px !important;
    }

    & .whatWeDoContent {
      p {
        margin-top: 0.3rem;
      }
    }
    .marginClass {
      padding-top: 2rem;
    }
    .underDataWhatwedo {
      span {
        font-size: 24px !important;
        padding-bottom: 0.3rem;
      }
      h1 {
        font-size: 24px !important;
      }
    }
  }
  .ourSkillContr {
    padding-top: 0.5rem !important;
  }

  .ourSkillHeading {
    h2 {
      font-size: 34px !important;
    }
  }
  .OurserHead {
    h1 {
      font-size: 34px !important;
    }
    p {
      font-size: 16px !important;
    }
  }
  .ourServiVemainDiv {
    padding-top: 1.4rem !important;
  }

  .whatWeDo {
    & .underDataWhatwedo {
      h1 {
        font-size: 22px !important;
        letter-spacing: 1px;
      }
    }
  }
  .paddingNoneee {
    padding: 0 !important;
  }

  .whatWEdoHead {
    h1 {
      letter-spacing: 1px;
      text-align: center;
      white-space: wrap !important;
      font-size: 31px !important;
    }
  }
}
