.Industry {
  padding-top: 2rem;
}
.indutryData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 2rem 1rem;
  background-color: var(--light-bg);
  i {
    font-size: 45px;
    margin-bottom: 0.5rem;
  }
  h1 {
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-top: 0.5rem;

    font-size: 25px;
    font-weight: 700;
  }
  p {
    text-align: center;
    line-height: 1.7;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 1px;
    padding-top: 0.6rem;
    font-size: 15px;
    font-weight: 400;
  }
}

.industryHeadline {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;

  h1 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 45px;
  }
  p {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
    color: var(--primary-color);
  }
}

.ReadMoreDivIndust {
  width: 100%;
  display: flex;
  justify-content: center;

  .explorBttnDiv {
    width: 50%;
    margin-top: 0.5rem;
  }
}

@media (max-width: 768px) {
  .industryHeadline {
    h1 {
      font-size: 31px;
    }
    p {
      font-size: 16px;
      margin: 0;
    }
  }
  .indutryData {
    h1 {
      font-size: 22px;
      margin: 0;
    }
    p {
      letter-spacing: normal;
    }
  }
  .marginReducer {
    margin-top: 2rem !important;
  }

  .industryDetailcont {
    padding: 10px;
    span {
      display: none;
    }
  }
}
