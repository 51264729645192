@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Oswald:wght@200..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.santosiFOntCLass {
  font-family: "Satoshi";
}
.fontSizeCLass {
  font-size: 16px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-light: #e13e0c;
  --primary-color: #d03202;
  --lightYellow: #ff8b00;
  --darkBlue-color: #1b2a2f;
  --light-orange: #feedeb;
  --offWhite-color: #dad9d7da;
  --light-bg: #f0f0f0;
  /* --black: #181818; */
  --black: #000000;
  --text-black: #181818;
  --light-borwn: #b5b8b8;
  --Light-black: #4c4c51;
  --white: #fff;
  --text-grey: #666666;
  --text-grey_dark: #999999;
  --light-grey: #cbc7c2;
  --dark-green: #008000;
  --Hovergreen: rgb(38, 126, 61);
  --cream-color: #f7f8f9;
  --light-pink-color: #fef0ee;

  --blue-text-Hover: #173467;
  --blue-text: #243f6e;
  --purpl-text: #8c3ef1;
  --border-line-hotel: #e1e1e1;
  --box-shadow: 0px 10px 30px 0px #0000000d;
  --dark-purpule: #94087a;
  --Pink: #ffcdf6;
  --light-orenge: #fff4f3;
  --light-blue: #f2f3f7;

  --red: #e93f33;
  --light-red: #f63a2e;
  --red-bg: rgb(246, 58, 46, 4%);
  --dark-red: #af2d24;
  --border-color: #edeeef;

  --gray-faded: #a5a5a8;
}

.App {
  font-family: "Oswald", sans-serif;
}
a {
  text-decoration: none;
}

.hidden {
  display: none !important;
}
::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-color) !important;
  border-radius: 50px !important;
}

@keyframes scroll {
  from {
    transform: translateX(100%); /* Start off-screen to the right */
  }
  to {
    transform: translateX(-100%); /* Move off-screen to the left */
  }
}
/* Initial state for the div */
.slide-in-div {
  position: fixed;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: var(--black);
  z-index: 900;
  transition: left 1s ease-out, width 1s ease-out;
}

.slide-in-div.show {
  width: 100%;
}
