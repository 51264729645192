.heroSectionFoxAc {
  padding: 1rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../Assets/heroImgFoxacpage.png");
  background-repeat: no-repeat;
  background-size: cover;

  .leftBoxFoxacd {
    font-family: "Raleway", sans-serif;
    padding: 1.4rem 1rem;
    border-radius: 12px;
    background-color: rgba(249, 249, 249, 0.95);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    h1 {
      color: var(--primary-color);
      font-size: 26px;
      font-weight: 600;
    }
    h2 {
      line-height: 1.3;
      padding-top: 0.5rem;
      color: var(--blue-text);
      font-size: 20px;
      font-weight: 700;
    }
    h3 {
      line-height: 1.3;
      padding-top: 0.2rem;
      color: var(--back);
      font-size: 16px;
      font-weight: 600;
    }

    .listBoxTypes {
      padding-top: 1rem;

      p {
        color: var(--blue-text);
        font-size: 15px;
        font-weight: 400;
        margin: 0;
      }
    }
  }

  /*------Right Side data-------*/
  .RightBoxFoxacd {
    width: 100%;
    font-family: "Raleway", sans-serif;
    padding: 2rem 1.5rem;
    border-radius: 15px;
    background-color: rgba(249, 249, 249, 0.95);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 0 auto;

    .selectWorkingSta {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: var(--white);
      border: 1px solid #ccc;
      border-radius: 8px;
      transition: all 0.3s ease;
      padding: 13px 10px;
      cursor: pointer;
      position: relative;
      &:hover {
        border: 1px solid var(--primary-color);
      }
      h5 {
        color: #212529;
        margin: 0;
        font-size: 14px;
      }

      img {
        height: 12px;
        width: 12px;
      }

      .SelectWorkingDropdwn {
        top: 3rem;
        left: 0;
        width: 98%;
        position: absolute;
        background-color: var(--white);
        padding: 0.5rem;
        border-radius: 5px;
        display: flex;
        gap: 0.2rem;
        align-items: start;
        flex-direction: column;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
          rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        opacity: 0;
        max-height: 0;
        transition: opacity 0.4s ease, max-height 0.4s ease, transform 0.4s ease;
        transform: translateY(-20px);
        pointer-events: none;
      }

      .SelectWorkingDropdwn.open {
        opacity: 1;
        max-height: 300px;
        transform: translateY(0);
        pointer-events: auto;
      }

      .SelectWorkingDropdwn button {
        padding: 0.5rem;
        border: none;
        background-color: transparent;
        font-size: 16px;
        color: var(--black);
        width: 100%;
        text-align: start;
        transition: all 0.3s ease;

        &:hover {
          color: var(--white);
          background-color: var(--primary-color);
        }
      }
    }
  }

  h1 {
    font-size: 28px;
    font-weight: 700;
    color: #333;
    margin-bottom: 1rem;
  }

  .formDataFocAcedmy {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .form-control {
    font-size: 15px;
    padding: 13px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: all 0.3s ease;

    &::placeholder {
      color: var(--black);
    }
  }

  .form-control:focus {
    border-color: var(--primary-color);
    outline: none;
    box-shadow: none;
  }

  .submit-btn {
    font-size: 14px;
    background-color: var(--primary-color);
    color: white;
    padding: 12px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }

  .submit-btn:hover {
    background-color: darken(var(--primary-color), 10%);
    transform: translateY(-2px);
  }

  .submit-btn:focus {
    outline: none;
    background-color: var(--primary-color);
  }

  .error {
    margin-left: 10px;
    color: var(--primary-color) !important;
    font-size: 12px !important;
  }
}

.termAndCOnditionDIv {
  display: flex;
  align-items: flex-start;
  gap: 7px;
  padding: 4px;

  input {
    height: 20px;
    width: 20px;
  }

  p {
    color: var(--black);
    font-size: 13px;
    font-weight: 300;

    margin: 0;
  }
}

.submitFormFoxAcd {
  padding-top: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    border: none;
    width: 90%;
    padding: 0.8rem 0;
    background-color: var(--blue-text);
    color: var(--white);

    &:hover {
      background-color: var(--blue-text-Hover);
    }
  }
}

.bgCOlor {
  font-family: "Raleway", sans-serif;
  background-color: #efeffd;
}
.bestDigitlMarkingDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  h1 {
    font-size: 35px;
    font-weight: 700;
  }

  .bestDigiHEad {
    margin-top: 2rem;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bestDigiData {
    margin-top: 2rem;
    background-color: var(--white);
    padding: 2rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 12px;
    p {
      line-height: 1.5;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
    }
  }

  a {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

.thumbnailcontainer {
  border: 7px solid var(--white);
  border-radius: 24px;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  background-image: url("../../Assets/youtube.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
  width: 100%;
  max-width: 80%;
  position: relative;

  img {
    height: 55px;
    width: 85px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .overLayclass {
    background-color: rgba(0, 0, 0, 0.18);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
  }
}

.harnessDIv {
  font-weight: 400;
  padding-top: 0.5rem;
  text-align: center;
  font-size: 16px !important;
  color: var(--primary-color);
}
/*------------------------------------------------------------*/

.adsDIv,
.JobOpportunitidiv {
  margin-top: 1rem;

  display: flex;
  background-color: var(--white);
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  img {
    height: 50px;
    width: 50px;
  }

  .contentAdts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    label {
      text-align: center;
      padding-top: 0.5rem;
      font-size: 15px;
      font-weight: 400;
      white-space: nowrap;
    }
  }
}
.adsDIv {
  justify-content: start;
  flex-wrap: wrap;
}
.JobOpportunitidiv {
  background-color: transparent;
  padding: 0;
  .jobOppCOnt {
    height: 100%;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background-color: var(--white);
  }
}
.letswtyDIv {
  width: 80%;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 40%;
    border: none;
    color: var(--white);
    padding: 0.5rem 1rem;
    font-size: 22px;
    background-color: var(--blue-text);
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--blue-text-Hover);
    }
  }
}
.adItem {
  flex: 1 1 calc(50% - 10px);
  box-sizing: border-box;
  min-width: 200px;
}
@media (max-width: 500px) {
  .adItem {
    flex: 1 1 calc(50% - 10px);
    min-width: 0;
  }
}
@media (max-width: 992px) {
  .contentAdts {
    img {
      margin-top: 1rem;
    }
  }
  .jobOppCOnt {
    label {
      white-space: wrap !important;
    }
  }
}

@media (min-width: 501px) and (max-width: 992px) {
  .adsDIv {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .adItem {
    flex: 1 1 calc(25% - 15px);
    min-width: 0;
  }
}

@media (min-width: 992px) {
  .adsDIv {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .adItem {
    flex: 1 1 auto;
    min-width: 0;
  }
}
/*----------What is Google------------------*/
.whatGoggleCont {
  padding-top: 2rem;
}
.WHatISgoogle {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0.8rem;
  border-radius: 5px;
  background-color: var(--white);
  border: 1px solid var(--black);
  margin-top: 0.8rem;
  img {
    height: 30px;
    width: 30px;
  }

  h2 {
    font-size: 18px;
    color: var(--black);
    font-weight: 600;
    margin: 0;
  }
}

/*---------------Top Course img ----------------*/

.topcourse {
  font-family: "Raleway", sans-serif;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2rem;
  .topCopurseHead {
    display: flex;
    justify-content: center;
    h1 {
      font-weight: 600;
      font-size: 35px;
    }
  }

  .topCourseData {
    width: 100%;
    margin-top: 2rem;

    img {
      width: 100%;
      object-fit: cover;
    }

    .placeLabl {
      display: flex;
      justify-content: center;
      width: 91.5%;
      left: 12px;
      position: absolute;
      bottom: 0;
      padding: 0.3rem;
      text-align: center;
      color: var(--white);
      background-color: #00000088;
      backdrop-filter: blur 2px;
      font-size: 16px;
      font-weight: 700;
      p {
        margin: 0;
      }
    }
  }
}

/*----------------------------------Certificate div----------------*/

.certificationContainrr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.certificationDiv {
  margin-top: 3rem;
  border-radius: 12px;
  border: 1.5px solid var(--blue-text);
  font-family: "Raleway", sans-serif;
  overflow: hidden;
  width: 100%;
  max-width: 90%;
  .lefSideD {
    height: 100%;
    padding: 2rem;
    background-color: var(--blue-text);

    h2,
    h1 {
      color: var(--white);
      font-size: 26px;
      font-weight: 700;
    }
    h1 {
      font-weight: 700;
      font-size: 33px;
    }

    .old-price {
      text-decoration: line-through;
      position: relative;
      color: var(--lightGrey);
    }
    h3 {
      font-weight: 700;
      color: var(--lightYellow);
      font-size: 23px;
      margin: 0;
    }
    h4 {
      font-weight: 400;
      color: var(--lightYellow);
      font-size: 23px;
      margin: 0;
    }
    p {
      font-weight: 400;
      color: var(--white);
      font-size: 23px;
      margin: 0;
    }

    button {
      margin-top: 2rem;
      border-radius: 5px;
      border: none;
      padding: 0.5rem 3rem;
      font-size: 16px;
      background-color: var(--white);
      color: var(--blue-text);
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      transition: background-color 0.3s ease, color 0.3s ease,
        box-shadow 0.3s ease;

      &:focus {
        outline: none !important;
      }
      &:hover {
        color: var(--back);
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
          rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        background-color: var(--offWhite-color);
      }
    }
  }
  .righSideDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    img {
      background-size: cover;
      width: 100%;
      height: 100%;
    }
  }
}

/*-----------Batch Div classs SHow---------*/

.BatchDivShow {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .widthClass {
    width: 90%;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    background-color: var(--white);

    border-radius: 12px;
  }
  .boxDiv,
  .boxDivlast,
  .boxDiv3 {
    padding: 1.5rem 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
    border-right: 1px solid var(--black);
    h1 {
      font-size: 26px;
      color: var(--lightYellow);
      font-weight: 700;
    }
    h2 {
      font-size: 22px;

      font-weight: 400;
    }
  }

  .boxDivlast {
    border: none;
  }
}
/*--------------------------Book a demo Button---------------*/

.bookNowButtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 3rem;
  font-family: "Raleway", sans-serif;
  button {
    font-size: 21px;
    border: none;
    width: 25%;
    font-weight: 700;
    padding: 0.7rem;
    background-color: var(--blue-text);
    transition: background-color 0.3s ease;
    color: var(--white);
    &:hover {
      background-color: var(--blue-text-Hover);
    }
  }
}
@media (max-width: 768px) {
  .heroSectionFoxAc {
    height: 100%;
    padding-bottom: 1rem;

    .RightBoxFoxacd {
      padding: 1.3rem 0.8rem;
    }
    h1 {
      font-size: 23px !important;
      margin-bottom: 0.2rem;
    }
    h2 {
      font-size: 19px !important;
    }
    h3 {
      font-size: 15px !important;
    }
  }

  .diflex {
    display: flex;
    justify-content: center;
  }
  .thumbnailcontainer {
    max-width: 100%;
  }

  .bestDigitlMarkingDiv {
    padding: 0px;

    .bestDigiData {
      padding: 1.5rem 0.7rem;
    }
  }

  .letswtyDIv {
    width: 90%;

    button {
      font-size: 20px;
      width: 100%;
    }
  }
  .whatGoggleCont {
    padding-bottom: 2rem;
  }

  .reverseShow {
    flex-direction: column-reverse;
  }

  .adsDIv,
  .JobOpportunitidiv {
    max-width: 100%;
    justify-content: center;
  }

  .whatGoggleCont {
    display: flex;
    justify-content: center;
  }
  .certificationDiv {
    max-width: 100%;
  }

  .paddingNoneClass {
    padding: 0;
  }

  .bestDigitlMarkingDiv {
    h1 {
      text-align: center;
      font-size: 25px;
    }
  }
  .BatchDivShow {
    .widthClass {
      width: 100%;
    }
  }

  .whatGoggleCont {
    padding: 2rem 0.5rem;
  }
  .topCopurseHead {
    h1 {
      text-align: center;
      font-size: 25px !important;
    }
  }

  .boxDiv3 {
    border-right: none !important;
  }
  .bordeLft {
    border-left: 1px solid var(--black);
  }
  .bordeRight {
    border-right: 1px solid var(--black);
  }
  .boxDiv3 {
    border-left: none !important;
  }

  .BatchDivShow {
    h2 {
      font-size: 20px !important;
    }
    h1 {
      font-size: 22px !important;
    }

    .boxDiv,
    .boxDivlast,
    .boxDiv3 {
      padding: 1rem 0;
    }
  }

  .harnessDIv {
    font-size: 15px !important;
  }

  .WHatISgoogle {
    h2 {
      margin-left: 7px;
      font-size: 16px;
    }
  }

  .lefSideD {
    h2 {
      font-size: 24px !important;
    }
    h1 {
      font-size: 26px !important;
    }
    h3,
    p,
    h4 {
      padding-top: 2px;
      font-size: 20px !important;
    }
  }
}
@media (max-width: 951px) {
  .adsDIv {
    flex-wrap: wrap;
  }
}
@media (max-width: 991px) {
  .boxDiv3 {
    border: none;
  }
}

@media (max-width: 768px) {
  .paddingCls {
    padding: 0;
  }

  .bookNowButtn {
    button {
      width: 70%;
    }
  }
}

@media (max-width: 992px) {
  .boxDiv3 {
    border-right: none !important;
  }
}
