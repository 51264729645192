.industryDetailcont {
  span {
    padding: 5px;
    background-color: var(--primary-color);
  }
  h1 {
    padding: 5px;
    background-color: var(--primary-color);
  }
}
.blogDetaiuldata {
  p {
    line-height: 2;
  }
}

.RealIndiData {
  margin-top: 3rem;
  h5 {
    font-size: 26px !important;
    text-transform: uppercase;
    font-weight: 600;
  }

  p {
    line-height: 2;
  }
}

/*-----Real State Data-----*/
.Letgrowdata {
  font-family: "Poppins", sans-serif !important;
  h1 {
    font-size: 28px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
  }

  p {
    line-height: 2;
    font-size: 15px;
  }
  li {
    line-height: 2.2;
    font-size: 15px;
    margin-left: 0.5rem;
  }
}
.marginClassHealth {
  margin-top: 3rem;
}
@media screen and (max-width: 768px) {
  .RealIndiData {
    h5 {
      font-size: 20px !important;
    }
    p {
      line-height: 1;
    }
  }

  .Letgrowdata {
    font-family: "Poppins", sans-serif !important;
    h1 {
      font-size: 20px !important;
    }

    p {
      line-height: 1;
    }

    li {
      line-height: 1;
    }
  }
}
