.contactUsCOntr {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: var(--darkBlue-color);
  height: 100%;
  font-family: "Poppins", sans-serif;
  .letGetThis {
    min-height: 30vh;
    background-color: var(--black);

    align-items: center;
    justify-content: space-between;

    padding: 2rem;
    border-radius: 20px;
    h1 {
      color: var(--white);
      font-weight: 500;
      font-size: 40px;
      margin: 0;
    }
    p {
      color: var(--white);
      font-weight: 400;
      margin: 0;
    }

    .leftData {
      .orengliLine {
        border-radius: 20px;
        height: 3px;
        width: 20%;
        margin-top: 1rem;
        background-color: var(--primary-color);
      }
    }
  }
}

.formDataconct {
  margin-top: 3rem;

  label {
    display: flex;
    align-items: center;
    color: var(--white);
    font-size: 16px;
    margin-bottom: 1rem;
  }
  span {
    margin: 0;
    margin-left: 5px;
    color: var(--primary-color);
  }

  input:-webkit-autofill,
  textarea:-webkit-autofill {
    background-color: transparent !important;
    box-shadow: 0 0 0 30px transparent inset !important;
  }

  input:-webkit-autofill:focus,
  textarea:-webkit-autofill:focus {
    background-color: transparent !important;
    box-shadow: 0 0 0 30px transparent inset !important;
  }
}

.formCOntact {
  background-color: transparent;
  box-shadow: none !important;

  input {
    font-size: 14px !important;
  }
  input::placeholder,
  textarea::placeholder {
    color: #627575 !important;
  }

  select {
    font-size: 14px !important;
  }
  textarea {
    height: 140px;
    resize: none;
    font-size: 14px;
  }
}

.theseAReDIv {
  span {
    margin: 0;
    margin-left: 5px;
    color: var(--primary-color);
  }
  label {
    margin-bottom: 0.4rem !important;
  }
}

.BOXdIVcONTCT {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 5px;
    padding: 3px;
    transition: outline 0.3s ease;
    cursor: pointer;
  }

  img.active {
    outline: 2px solid var(--primary-color);
  }
}

/*------Submitbttn-----------*/

.submitBttn {
  display: inline-flex;
  margin-top: 1rem;
  height: 50px;
  width: 180px;
  border: 2px solid #bfc0c0;
  color: #bfc0c0;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.8em;
  letter-spacing: 1.5px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  button {
    color: #bfc0c0;
    text-decoration: none;
    letter-spacing: 1px;
    background-color: transparent;
    border: none;
    font-size: 14px;
  }
}

.submitBttn {
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    .translate {
      left: 0;
    }
  }

  button {
    position: relative;
    transition: all 0.45s ease-Out;

    &:hover {
      color: var(--white);
    }
  }
  .translate {
    transform: rotate(50deg);
    width: 100%;
    height: 250%;
    left: -200px;
    top: -30px;
    background: var(--primary-color);
    position: absolute;
    transition: all 0.3s ease-Out;
  }
}

@media screen and (max-width: 768px) {
  .BOXdIVcONTCT {
    img {
      max-width: 287px;
      overflow: hidden;
      border-radius: 5px;
      padding: 3px;
    }
  }

  .contactUsCOntr {
    .letGetThis {
      padding: 3rem 0rem;
      margin: 1px;
      min-height: 100%;
      h1 {
        font-size: 30px;
      }
      p {
        font-size: 15px;
        padding-top: 1.3rem;
      }
    }
  }

  .paddnngZero {
    padding: 0 !important;
  }
}
@media screen and (max-width: 560px) {
  .BOXdIVcONTCT {
    img {
      max-width: 250px;
      overflow: hidden;
      border-radius: 5px;
      padding: 0.3rem;
    }
  }
}
