.aboutContnt {
  p {
    padding-top: 1rem;
    font-size: 16px;
    line-height: 2;
  }
}

.whoiWeAreDiv {
  display: flex;
  align-items: center;
  padding: 5rem 0;
  background-color: var(--black);
  font-family: "Open Sans", sans-serif;
  h1 {
    font-size: 24px;
    letter-spacing: 2px;
    color: var(--primary-color);
    font-weight: 700;
    margin-left: -2rem;
    text-transform: uppercase;
    padding-bottom: 0.5rem;
  }
  p {
    font-size: 17px;
    line-height: 2;
    color: var(--offWhite-color);
    font-family: 400;
  }

  span {
    color: var(--primary-color);
  }
}
.marginTopcLASS {
  margin-top: 2.5rem;
}

@media (max-width: 768px) {
  .whoiWeAreDiv {
    padding: 3rem 0;
    h1 {
      margin-left: 0rem;
    }
    p {
      font-size: 16px;
    }
  }
  .aboutContnt {
    padding-top: 3rem;
  }

  .aboutContnt {
    padding-top: 1.5rem;
  }
  .marginTopcLASS {
    margin-top: 1.5rem;
    h2 {
      font-size: 21px !important;
    }
  }
  .pdding1rem {
    padding-top: 0.3rem !important;
  }
}
