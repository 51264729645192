/*------------------------------ Footer Section -------------------------*/
.FooterSection,
.FooterSectionHome {
  /* position: sticky; */
  bottom: 0;
  z-index: 1;
  padding: 1.5rem 1rem 0.3rem 1rem;

  background-color: var(--black) !important;
  border-top: 8px solid var(--darkBlue-color);
  margin-top: 2rem;

  h5 {
    font-size: 24px;
    margin-bottom: 0.8rem;
    font-weight: 600;
    color: var(--white);
  }

  /*------------------------------ Link Styling -------------------------*/

  .FooterDowndata {
    justify-content: space-between;
    a {
      position: relative;
      text-decoration: none;

      padding-bottom: 5px;
      transition: color 0.4s ease;

      &::after {
        /*------------------------------ Underline Effect -------------------------*/
        content: "";
        position: absolute;
        width: 0%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: var(--primary-color);
        transition: width 0.3s ease-in-out;
      }

      &:hover {
        /*------------------------------ Hover Effect -------------------------*/
        color: var(--primary-color);

        &::after {
          width: 100%;
        }
      }
    }
  }

  /*------------------------------ List Data Styling -------------------------*/
  .ListData {
    gap: 1rem;

    li {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      margin-top: 4px;
      text-decoration: none;
      list-style: none;
      font-size: 16px;
      color: var(--black);
    }

    a {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      color: var(--white);
      text-decoration: none;
    }
    button {
      text-align: left;
      border: none;
      background-color: transparent;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      color: var(--white);
      text-decoration: none;
      position: relative;
      padding: 0;
      padding-bottom: 5px;
      transition: color 0.4s ease;

      &::after {
        /*------------------------------ Underline Effect -------------------------*/
        content: "";
        position: absolute;
        width: 0%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: var(--primary-color);
        transition: width 0.3s ease-in-out;
      }

      &:hover {
        /*------------------------------ Hover Effect -------------------------*/
        color: var(--primary-color);

        &::after {
          width: 100%;
        }
      }
    }
    p {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      color: var(--white);
      text-decoration: none;
    }
  }

  /*------------------------------ Social Media Links -------------------------*/
  .socialMediaLink {
    margin-top: -2rem;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    img {
      width: 32px;
    }
  }

  /*------------------------------ Social Icons Styling -------------------------*/
  .SocialIcons {
    margin-top: 0.5rem;
    gap: 0.8rem;

    a {
      color: var(--white);
      transition: transform 0.3s ease;
      border: 2px solid var(--primary-color);
      border-radius: 50%;
      padding: 7px;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;

      transition: transform 0.3s ease;
    }
    a:hover {
      transform: scale(1.1);
    }
  }

  /*------------------------------ Left Side Content -------------------------*/
  .leftSide {
    display: flex;
    flex-direction: column;

    h5 {
      font-style: italic;
      padding-top: 0.8rem;
      font-size: 35px;
      color: var(--white);
    }

    p {
      padding-bottom: 0.8rem;
      font-size: 16px;
      color: var(--white);
      margin: 0;
    }
  }

  /*------------------------------ Right Side Content -------------------------*/
  .rightSide {
    p {
      font-size: 16px;
      color: var(--white);
      margin: 0;
    }

    img {
      margin-left: 5px;
      width: 32px;
    }
  }

  /*------------------------------ Footer Buttons -------------------------*/
  .ButtonsBottm {
    margin-top: 0.8rem;
    gap: 0.6rem;

    button {
      padding: 0.4rem 0.7rem;
      border: transparent;
      background-color: var(--white);
      color: var(--primary-color);
      border-radius: 12px;
      font-size: 16px;
      transition: background-color 0.3s ease, transform 0.3s ease,
        color 0.3s ease;

      &:hover {
        /*------------------------------ Button Hover Effect -------------------------*/
        background-color: var(--primary-colorHover);
        color: var(--white);
        transform: scale(1.05);
      }
    }
  }

  /*------------------------------ Icon Styling -------------------------*/
  .giveLInkIcom {
    height: 17px;
    width: 17px;
  }

  /*------------------------------ Give Link Section -------------------------*/
  .GiveLinkSection {
    display: flex;
    gap: 11px;
    align-items: center;

    label {
      font-size: 16px;
    }
  }

  /*------------------------------ Contact Section -------------------------*/
  .ContactSec {
    flex-wrap: wrap;

    p {
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      white-space: nowrap;
    }

    button {
      padding: 3px;
      text-align: unset;
      background-color: transparent;
      border: none;
      font-size: 16px;
      margin: 0;
      font-family: "Poppins", sans-serif;
    }
  }

  /*------------------------------ Footer Top Styling -------------------------*/
  .FOoterTOp {
    margin-bottom: 2.5rem;
    padding: 0.8rem 1.2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    background-color: var(--primary-color);
  }

  /*------------------------------ Copyright Section -------------------------*/
  .copyRightText {
    display: flex;
    justify-content: center;
    p {
      margin: 0;
      font-size: 13px;
      font-family: "Poppins", sans-serif;
      color: var(--white);
      font-weight: 400;
    }
  }
}

.FooterSectionHome {
  margin-top: 0px !important;
}
@media screen and (max-width: 600px) {
  .FooterSection,
  .FooterSectionHOme {
    .socialMediaLink {
      margin-top: 0rem;
    }
  }

  .copyRightText {
    margin-top: 1rem;
  }
}
