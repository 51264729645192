.avhivedDiv,
.avhivedDiv2 {
  overflow: hidden !important;
  margin-top: 1.5rem;
  background-color: var(--light-bg);

  padding: 0.5rem 1rem;
  font-family: "Poppins", sans-serif;

  h1 {
    margin-top: 2rem;

    font-size: 16px;
    font-weight: 600;
  }
  p {
    line-height: 1.5;
    letter-spacing: 1px;
    padding-top: 1rem;
    font-size: 14px;
    font-weight: 400;
  }

  img {
    max-width: 100% !important;
  }
}

.imgOverlAy {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.427);
  transition: width 0.6s ease-in-out;
  z-index: 1;
}

.rightService {
  z-index: 4;
}
.detailServHeroSubBlogg {
  width: 100%;

  position: relative;

  h2 {
    padding: 2px 2px;
    line-height: 1.5;
    background-color: var(--primary-color);
  }
}

.theFoxTxtbttm {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  label {
    font-size: 16px;
    font-weight: 600;
    color: var(---black);
  }
}

.avhivedDiv2 {
  margin-top: 2rem;
  padding: 1rem 1rem;
}
@media (max-width: 768px) {
  .avhivedDiv,
  .avhivedDiv2 {
    p {
      letter-spacing: normal;
    }

    img {
      height: 100% !important;
    }
  }
}
@media (max-width: 992px) {
  .rightService {
    justify-content: center;
    position: absolute;
    top: 0;
    left: 4%;
  }
}

@media (min-width: 1400px) {
  .detailServHeroSubBlogg {
    max-width: 50%;
  }
}

.blogcontent_container_Revert * {
  all: revert !important;
}
