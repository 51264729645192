.testimonialDIv {
  padding-top: 3rem;
  background-color: var(--white);

  .ourTestHead {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
    h1 {
      white-space: nowrap;
      overflow: hidden;
      text-transform: uppercase;
      font-size: 45px;
      font-weight: 600;
    }
    .for_h1_ts {
      white-space: nowrap;
      overflow: hidden;
      text-transform: uppercase;
      font-size: 45px;
      font-weight: 600;
    }
  }

  .testiMonialContnr {
    background-color: var(--light-bg);
    font-family: sans-serif;
    padding: 2rem;
    .testHead {
      display: flex;
      justify-content: center;
    }
  }

  .testimonialContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    height: 100%;
  }

  .imgContainer {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .testimonialImg {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }

  .textContainerTexti {
    margin-top: 2rem;
    position: relative;
    font-size: 16px;
    background-color: var(--primary-light);
    padding: 1rem;
    color: var(--white);
    font-family: unset;
    p {
      font-weight: 300;
      text-align: justify;
      margin: 0;
    }
  }
  .tringleClass {
    position: absolute;
    top: -10%;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 17px solid var(--primary-light);
    transform: translateX(-50%);
  }

  .slick-dots li button:before {
    font-size: 5px;
    color: var(--black);
    transition: font-size 0.3s ease, color 0.3s ease;
  }

  .slick-dots li.slick-active button:before {
    color: var(--primary-light);
    font-size: 8px;
  }
}

@media (max-width: 768px) {
  .testimonialDIv {
    .testiMonialContnr {
      padding: 0;
    }
    .testimonialContent {
      padding: 20px 10px;
    }
  }
}
@media (max-width: 530px) {
  .testimonialDIv {
    .tringleClass {
      top: -5%;
    }
  }
}
