.hero-section {
  position: relative;
  overflow: hidden;
  height: calc(100vh - 82px);
  background-color: var(--darkBlue-color);

  .slick-track {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .slick-slider {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .heroCOntentContainer {
    position: relative;
    display: flex !important;
    gap: 1rem;
    align-items: center !important;
    height: calc(100vh - 82px);
    width: 100vw !important;
  }
  .slick-initialized .slick-slide {
    display: flex;
  }
}
.heroLeftImg {
  display: flex !important;
  width: 100% !important;
  height: 100%;
  object-fit: cover;
}
.rightSideContent {
  display: flex;
  margin-top: -3rem;
  align-items: center;
  h1 {
    padding: 0.3rem 1rem;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 700;
    font-size: 45px;
    background-color: var(--primary-color);
    color: var(--white);

    /* width: 0; */
    transition: width 0.8s ease-in-out;
  }
}
.SideContent {
  display: flex;
  flex-direction: column;

  .generativeDiv {
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    white-space: nowrap;
    /* width: 0; */

    transition: width 0.8s ease-in-out;
    p {
      letter-spacing: 1px;
      padding: 0.3rem 1rem;
      font-weight: 500;
      margin: 0;
      font-size: 15px;
      color: var(--primary-color);
      background-color: var(--white);
    }
  }
}

.contactUsBttnHero {
  margin-top: 2rem;

  button {
    padding: 0.7rem 3.5rem;

    border: none;
    background-color: rgba(0, 0, 0, 0.3);
    letter-spacing: 5px;
    color: var(--white);
    font-weight: 100;
    font-size: 20px;
    position: relative;
    overflow: hidden;
    opacity: 0;
    transform: translateY(20px);
    backdrop-filter: blur(2px);
    transition: background 0.35s linear, font-weight 0.35s linear,
      letter-spacing 0.35s linear;

    svg {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    rect {
      fill: none;
      stroke: var(--white);
      stroke-width: 2;
      stroke-dasharray: 422, 0;
      transition: all 0.35s linear;
    }

    &:hover {
      font-weight: 900;
      letter-spacing: 4px;

      rect {
        stroke-width: 5;
        stroke-dasharray: 15, 310;
        stroke-dashoffset: 48;
        transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
      }
    }
  }
}

.contactUsBttnHero button.slideUp {
  opacity: 1;
  transform: translateY(0);
}
/*----------Animation-----------*/

.animate-width {
  width: 100% !important;
}

/*--------------------Left Side Class show anymation---------------*/
.BlackColorClass {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--darkBlue-color);
  transition: width 0.6s ease-in-out;
  z-index: 1;
}
.overlaytEXT {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: transparent;
  height: 100%;
  background-color: var(--darkBlue-color);
  transition: width 0.6s ease-in-out;
  z-index: 1;
}

.heroLeftImg {
  z-index: 0; /* Ensures image is below the overlay */
}

/*---------------Mouse hover effect ----------------*/

.mouse_scroll {
  cursor: pointer;
  position: absolute;
  bottom: 2rem;
  z-index: 5;
  display: block;
  left: 43%;
  transform: translateX(-43%);
  margin: 0 auto;
}

.m_scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);

  border-right: 2px solid var(--white);
  border-bottom: 2px solid var(--white);
  margin: 0 0 3px 4px;

  width: 13px;
  height: 15px;
}

.unu {
  margin-top: 1px;
}

.unu,
.doi,
.trei {
  -webkit-animation: mouse-scroll 1s infinite;
  -moz-animation: mouse-scroll 1s infinite;
  animation: mouse-scroll 1s infinite;
}

.unu {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;

  animation-direction: alternate;
  animation-delay: alternate;
}

.doi {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;

  animation-delay: 0.2s;
  animation-direction: alternate;

  margin-top: -6px;
}

.trei {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;

  animation-delay: 0.3s;
  animation-direction: alternate;

  margin-top: -6px;
}

.mouse {
  height: 30px;
  width: 20px;
  border-radius: 14px;
  transform: none;
  border: 2px solid white;
  top: 170px;
}

.wheel {
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: white;
  position: relative;

  height: 4px;
  width: 4px;
  border: 2px solid #fff;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

.wheel {
  -webkit-animation: mouse-wheel 0.6s linear infinite;
  -moz-animation: mouse-wheel 0.6s linear infinite;
  animation: mouse-wheel 0.6s linear infinite;
}

@-webkit-keyframes mouse-wheel {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-moz-keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}
@-o-keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}
@keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}

@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
/*--------------slider Indexing SHow---------------*/

.sliderIndexingShow {
  display: flex;
  gap: 0.6rem;
  position: absolute;
  z-index: 20;
  bottom: 1rem;
  right: 3%;
}

.sliderIndexingShow button {
  border: none;
  background-color: transparent;
  font-size: 16px;
  color: white;
  transition: all 0.3s ease;
  font-weight: 700;
}

.sliderIndexingShow .active {
  font-size: 18px;
  color: var(--primary-color);
  transform: scale(1.2) translateX(0) translateY(0);
  animation: digitScroll 0.5s ease-in-out;
}

@keyframes digitScroll {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(-50%);
  }
  50% {
    opacity: 0.5;
    transform: scale(1) translateY(0);
  }
  100% {
    opacity: 1;
    transform: scale(1.2) translateY(0);
  }
}
/*-----------Media Query--------------*/

@media all and (max-width: 992px) {
  .SideContent {
    left: 4%;

    z-index: 22;
    position: absolute;
  }

  .mouse_scroll {
    bottom: -3rem;
    left: 50%;
  }
}
@media (max-width: 768px) {
  .heroLeftImg {
    height: 100vh;
  }
}

/*--------------responsiveMobileVideo---------------*/

@media (max-width: 615px) {
  h1 {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .heroLeftImg {
    height: 100vh;
  }
}
@media all and (max-width: 515px) {
  .rightSideContent {
    h1 {
      white-space: wrap;
      font-size: 34px;
    }
  }
  .SideContent {
    .generativeDiv {
      flex-wrap: wrap;

      p {
        margin-top: 0.5rem;
      }
    }
  }
}
.fontSizeAf576 {
  color: #fff;
}
@media screen and (max-width: 576px) {
  .fontSizeAf576 {
    font-size: 24px !important;
    color: #fff;
  }
}
