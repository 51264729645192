.ourSkillContr {
  padding-top: 2rem;
  background-color: var(--black);

  .bothDivOur {
    display: flex;
    align-items: center;
  }
  .leftSideImg {
    position: relative;
    img {
      object-fit: cover;
      height: 100vh;
      width: 100%;
    }
  }

  .rightSideCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;

    .ourSkillHeading {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 3rem;

      h2 {
        letter-spacing: 2px;
        font-size: 35px;
        color: var(--primary-color);
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    .skillBar {
      /* display: flex; */
      align-items: center;
      margin: 1.2rem 0;
      position: relative;

      .bar {
        flex: 2;
        background-color: #333;
        border-radius: 20px;
        overflow: hidden;
        margin: 0 10px;
        .progress {
          height: 2px;
          background-color: var(--primary-color);
          transition: width 0.4s ease;
        }
      }
    }

    .bartxtShow {
      margin-bottom: 0.6rem;
      padding: 0 0.5rem;
      display: flex;
      justify-content: space-between;

      label {
        color: var(--primary-color);
        font-size: 15px;
        font-family: "Poppins", sans-serif;
      }
    }
  }
}

@media all and (max-width: 991px) {
  .leftSideImg {
    display: none;
  }
}
