.mainBLog {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: var(--white);
  .blogData {
    justify-content: space-between;
    h2 {
      font-size: 20px;
      max-width: 100%;

      img {
        width: 100%;
        transition: none;

        &:hover {
          transform: none;
        }
      }
    }
    h3 {
      padding-top: 0.5rem;
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      font-size: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
}

.blogData img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 200px;
}

.ReadMorediv {
  .explorBttn {
    button {
      text-transform: uppercase;
      width: 70%;
      padding: 1rem 1.5rem;
    }
  }
  .dateAndTxtDiv {
    padding-top: 1rem;
  }
}

.loadMoreBttn {
  padding-top: 2rem;
  display: flex;
  justify-content: center;

  button {
    border: none;
    color: var(--white);
    background-color: var(--darkBlue-color);
    padding: 0.7rem 2.5rem;
    font-weight: 400;
    letter-spacing: 1px;

    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
      background-color: var(--black);
      transform: scale(1.05);
    }
  }
}

.spinner-square {
  display: flex;
  flex-direction: row;
  width: 90px;
  height: 51px;
  background-color: transparent;
  align-items: center;
  gap: 0.5rem;
}

.spinner-square > .square {
  width: 7px;
  height: 80px;

  border-radius: 4px;
}

.square-1 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s
    infinite;
}

.square-2 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 200ms
    infinite;
}

.square-3 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 400ms
    infinite;
}

@keyframes square-anim {
  0% {
    height: 10px;
    background-color: var(--primary-color);
  }
  20% {
    height: 10px;
  }
  40% {
    height: 20px;
    background-color: var(--primary-color);
  }
  80% {
    height: 10px;
  }
  100% {
    height: 10px;
    background-color: var(--primary-color);
  }
}
@media (max-width: 768px) {
  .blogData {
    h2 {
      letter-spacing: 0.5px;
    }
  }
}
