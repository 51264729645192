.recentWorkSection {
  background-color: var(--white);
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  min-height: 50vh;

  .Recentheading {
    display: flex;
    flex-direction: column;

    align-items: center;
    h1 {
      font-size: 45px;
      font-weight: 600;
      color: var(--black);
    }
    p {
      font-size: 18px;
      font-weight: 400;
      color: var(--black);
    }
  }
}

.recnt1 {
  margin-top: 2rem;
}

.marginBttm {
  margin-bottom: 3rem;
}
.imgDivRecent {
  height: 100%;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.textContainer,
.textContainer2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  h1 {
    white-space: nowrap;
    font-size: 26px;
    /* color: var(--primary-color); */
    font-weight: 600;
    text-transform: uppercase;
    transition: width 0.6s ease-in-out;
    /* background-color: var(--primary-color); */
  }
  .for_h2 {
    white-space: nowrap;
    font-size: 26px;
    color: var(--primary-color);
    font-weight: 600;
    text-transform: uppercase;
    transition: width 0.6s ease-in-out;
    /* background-color: var(--primary-color); */
  }

  h2 {
    letter-spacing: 1px;
    font-size: 26px;
    color: var(--black);
    font-weight: 600;
    text-transform: uppercase;
    transition: width 0.6s ease-in-out;
  }

  p {
    line-height: 2rem;
    font-size: 16px;
    letter-spacing: 1px;
    margin-top: 1.5rem;
    font-weight: 400;
  }
}

.textContainer2 {
  align-items: flex-start;
}

/*----------------Explore button animation -----------*/
.explorBttn {
  margin-top: 1rem;
  font-weight: 700;
  width: 80%;
  .btn-push-left {
    position: relative;
    background-color: var(--darkBlue-color);

    color: #fff;
    overflow: hidden;
  }
  button {
    width: 100%;
    padding: 1.2rem 2rem;
    white-space: nowrap;
    border: none;
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: 400;
  }

  .btn-push-left span {
    position: relative;
    left: -0.5em;
    transition: all 0.25s;
  }

  .btn-push-left::before,
  .btn-push-left::after {
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    background-color: var(--primary-color);
    transition: all 0.25s;
    color: #fff;
  }

  .btn-push-left::before {
    width: 25px;

    content: "";
    background-image: url("../../Assets/rightArrow.svg");
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    border-radius: 50% 0 0 50%;
    z-index: 1;
  }

  .btn-push-left::after {
    width: 100%;
    line-height: 2.5;
    transform: translate(100%, 0);
    content: attr(data-hover);
    text-align: left;
    text-indent: 8px;
  }

  .btn-push-left:hover span {
    left: -200%;
  }

  .btn-push-left:hover::before {
    right: 69%;
  }

  .btn-push-left:hover::after {
    right: 70%;
  }
}
/*----------madia Query---------*/

@media screen and (max-width: 768px) {
  .textContainer,
  .textContainer2 {
    margin-top: 1rem;

    h1 {
      font-size: 25px;
    }
    .for_h2 {
      font-size: 25px;
    }
    h2 {
      font-size: 24px;
    }
  }

  .recentWorkSection {
    padding: 1rem 0;
  }

  .recentWorkSection {
    & .Recentheading {
      h1 {
        font-size: 34px;
      }
      p {
        font-size: 16px;
      }
    }
  }

  .recnt1 {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .testimonialDIv {
    .ourTestHead {
      h1 {
        font-size: 34px !important;
      }
    }
  }

  .explorBttn {
    width: 100%;
    button {
      padding: 1rem 0rem;
    }
  }

  .imgDivRecent {
    padding: 0.5rem;
  }
}
