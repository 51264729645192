.HeaderCont {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1rem 1.5rem;
  background-color: var(--black) !important;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 499;
  width: 100%;

  .logoImgtxt {
    height: 50px;
    width: 101px;
  }
}
.downTOUp {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease-out, transform 0.5s ease-out;
}

.downTOUp.slideUp {
  opacity: 1;
  transform: translateY(0);
}
.toggleBarContnrr {
  display: flex;
  justify-content: space-between;
  width: 0%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 109;
  position: fixed;
  background-color: var(--darkBlue-color);
  transition: width 1.3s ease-in-out;
  overflow: hidden;

  &.open {
    width: 100%;

    .lefySide {
      width: 50%;
    }

    .righSideImg {
      width: 50%;
    }
  }
  &.expanded {
    .lefySide {
      width: 100%;
    }

    .righSideImg {
      width: 0%;
    }
  }
  .lefySide,
  .righSideImg {
    transition: width 0.9s ease-in-out 0.7s;
  }

  .lefySide {
    width: 100%;
    background-color: var(--darkBlue-color);
  }

  .righSideImg {
    width: 0%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }
}
.NavbarDatas {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.NavbarDatas button {
  text-transform: uppercase;
  color: var(--white);
  background-color: transparent;

  border: none;
  font-weight: 600;
  font-size: 35px;
  letter-spacing: 1px;
  position: relative;
  white-space: nowrap;
  width: 0;
  max-width: fit-content;
  overflow: hidden;
  opacity: 0;
  transition: width 1.5s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--primary-color);
    transition: width 0.3s ease-in-out;
  }

  &:hover {
    color: var(--primary-color);
    transform: scale(1.05);

    &::after {
      width: 100%;
    }
  }

  &:focus {
    box-shadow: none !important;
  }

  &.active {
    width: 100%;
    opacity: 1;
  }
}
.logoAndCrossIcon {
  width: 50%;
  top: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: absolute;

  .headerIconImgg {
    cursor: pointer;
    margin-left: 20px;
    height: 50px !important;
    width: 101px !important;
  }

  .toggleCloseImg {
    cursor: pointer;
    margin-right: 30px;
    height: 25px !important;
    width: 25px !important;
  }

  &.show {
    visibility: visible;
    transform: translateY(0);
    pointer-events: auto;
  }
}

@media all and (max-width: 768px) {
  .toggleBarContnrr {
    .lefySide {
      width: 100% !important;
    }

    .righSideImg {
      width: 0% !important;
    }
  }
  .logoAndCrossIcon {
    width: 100%;
  }
  .HeaderCont {
    .logoImgtxt {
      height: 45px;
      width: 95px;
    }
  }
  .logoimg2 {
    img {
      width: 35px;
    }
  }
  .HeaderCont {
    padding: 0.8rem 0.5rem;
  }
}
