.brandTrustdHead {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  font-size: 28px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.contrImng {
  margin-top: 2rem;
}

.divone,
.divlast {
  height: 100%;
  width: 100%;
  padding: 2.5rem 1rem;
  /* cursor: pointer; */
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    transition: opacity 0.3s ease, transform 0.3s ease;

    &:hover {
      opacity: 0.6;
      transform: scale(1.1);
    }
  }
}

.divlast {
  border: none;
}
.borderLeft {
  border-right: 1px solid var(--border-line-hotel);
}
.borderbottm {
  border-bottom: 1px solid var(--border-line-hotel);
}
/*--------------sport Button---------*/

.sportBttn {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 80%;
    height: 80%;
    position: relative;
    background: none;
    border: none;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.09);
  }
  span {
    padding: 0.5rem;
    width: 100%;
    height: 100%;
    height: auto;
    display: block;
    font-weight: 500;
    letter-spacing: 1px;
    color: var(--primary-color);
    white-space: normal;
    font-size: 15px;
  }

  button::before,
  button::after {
    content: "";
    width: 0;
    height: 1px;
    position: absolute;
    transition: all 0.2s linear;
    background: var(--primary-color);
  }

  span::before,
  span::after {
    content: "";
    width: 2px;
    height: 0;
    position: absolute;
    transition: all 0.2s linear;
    background: var(--primary-color);
  }
  button:hover::before,
  button:hover::after {
    width: 100%;
  }
  button:hover span::before,
  button:hover span::after {
    height: 100%;
  }

  .btn-4::after {
    right: 0;
    bottom: 0;
    transition-duration: 0.4s;
  }
  .btn-4 span::after {
    right: 0;
    bottom: 0;
    transition-duration: 0.4s;
  }
  .btn-4::before {
    left: 0;
    top: 0;
    transition-duration: 0.4s;
  }
  .btn-4 span::before {
    left: 0;
    top: 0;
    transition-duration: 0.4s;
  }
}
