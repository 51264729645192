.faq {
  max-width: 100%;
  margin: 20px auto;
  padding: 20px;
  font-family: "Raleway", sans-serif;
}

.faq__title {
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 35px;
    font-weight: 600;
  }
  p {
    font-size: 18px;
    font-weight: 500;
  }
}

.faq__content {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faq__item {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.faq__question {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 400;
  padding: 15px 20px;
  background: #f9f9f9;
  cursor: pointer;
  transition: background 0.3s;
  overflow: hidden;
}

.faq__question:hover {
  background: #f0f0f0;
}

.faq__question--active {
  background: #e0e0e0;
}
.faq__icon {
  font-size: 16px;
  transition: transform 0.3s ease-in-out;
}

.faq__icon--rotated {
  transform: rotate(180deg);
}

.faq__answer {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;

  background: #fff;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out,
    visibility 0s 0.3s;
}
.answerDIv {
  padding: 10px 15px;
}
.faq__answer--visible {
  max-height: 200px;
  opacity: 1;
  visibility: visible;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
@media (max-width: 768px) {
  .faq__title {
    h1 {
      text-align: center;
      font-size: 25px !important;
    }

    P {
      font-size: 15px !important;
    }
  }
  .faq__content {
    margin-top: 1.5rem;
  }
  .faq {
    margin-top: 3rem;
    padding: 2px;
  }
  .boxDiv {
    border-right: none !important;
  }
}
